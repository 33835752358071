<template>
  <BaseContainer>
    <PAYGCheckoutComplete />
  </BaseContainer>
</template>

<script>
import PAYGCheckoutComplete from '@components/Plans/PAYGCheckoutComplete'

export default {
  components: {
    PAYGCheckoutComplete
  },

  page: {
    title: 'Start Subscription'
  }
}
</script>
