import { pickedApiRequest } from './request'

export default {
  /**
   * @param {Object} data
   * @return {Object}
   */
  createPaymentIntent (data) {
    return pickedApiRequest.post('assessment/checkout/payment-intent', data)
      .then(response => {
        return response.data
      })
  },

  /**
   * @param {Object} data
   * @return {Object}
   */
  finishSubscriptions (data) {
    return pickedApiRequest.post('assessment/checkout/finish-subscriptions', data)
      .then(response => {
        return response.data
      })
  }
}
